<template>
  <div
    :class="$attrs.class"
    class="ui-input-wrapper"
    :style="$attrs.style"
  >
    <div class="ui-input-wrapper__info-box">
      <div class="ui-input-wrapper__info-slot" />

      <div
        v-if="$slots['top-right-info']"
        class="ui-input-wrapper__info-slot ui-input-wrapper__info-slot--right"
      >
        <slot name="top-right-info" />
      </div>
    </div>

    <div class="ui-input-wrapper__input-box">
      <div
        v-if="$slots.left"
        class="ui-input-wrapper__left-box"
      >
        <slot name="left" />
      </div>

      <label
        v-bind="getFilteredAttributes()"
        :class="{
          'ui-input-wrapper__label': true,
          'ui-input-wrapper__label--error': errors && errors.length,
          'ui-input-wrapper__label--focused': isFocused,
        }"
      >
        <div :class="{ 'ui-input-wrapper__input-slot': true, 'ui-input-wrapper__input-slot--focused': isFocused }">
          <slot />

          <div
            v-if="$slots['input-slot-right']"
            class="ui-input-wrapper__input-slot-right"
            :class="{
              'ui-input-wrapper__input-slot-right--error': errors && errors.length,
            }"
          >
            <slot name="input-slot-right" />
          </div>
        </div>

        <span
          :class="{
            'ui-input-wrapper__label-text': true,
            'ui-input-wrapper__label-text--error': errors && errors.length,
            'ui-input-wrapper__label-text--transform': isNotEmpty
          }"
        >
          {{ label }}
        </span>

        <div class="ui-input-wrapper__loading-bar-container">
          <div
            v-if="isLoading"
            class="ui-input-wrapper__loading-bar"
          />
        </div>
      </label>

      <div
        v-if="$slots.right"
        class="ui-input-wrapper__right-box"
      >
        <slot name="right" />
      </div>
    </div>

    <div class="ui-input-wrapper__info-box">
      <div class="ui-input-wrapper__info-slot">
        <Transition
          name="slide-y-up"
          mode="out-in"
        >
          <span
            v-if="errors && errors.length"
            key="errors"
            class="ui-input-wrapper__error-msg"
          >
            {{ errors }}
          </span>

          <span
            v-else-if="hints && hints.length"
            key="hints"
            class="ui-input-wrapper__hints"
          >
            {{ hints }}
          </span>
        </Transition>
      </div>

      <div
        v-if="$slots['bottom-right-info']"
        class="ui-input-wrapper__info-slot ui-input-wrapper__info-slot--right"
      >
        <slot name="bottom-right-info" />
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "InputWrapper",

  inheritAttrs: false,

  props: {
    errors: String,
    label: String,
    isLoading: Boolean,
    hints: String,
    isFocused: {
      type: Boolean,
      default: false,
    },
    isNotEmpty: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    getFilteredAttributes() {
      // eslint-disable-next-line no-unused-vars
      const { class: _, style: __, ...otherAttrs } = this.$attrs;
      return otherAttrs;
    }
  }
});
</script>

<style lang="scss">
$input-height: 37px;

// TODO: Move to separate file for transitions Vue3
//
.slide-y-up-enter-active, .slide-y-up-leave-active {
  transition: opacity .3s ease, transform .3s ease;
}
.slide-y-up-enter, .slide-y-up-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

$placeholder-color: #00000099;
$default-icon-color: rgba(0, 0, 0, 0.54);

.ui-input-wrapper {
  $root: &;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-top: 7px solid transparent;

  &__info-box {
    display: flex;
    padding: 2px 12px 0;
    justify-content: space-between;
    width: 100%;
  }

  &__info-slot {
    display: flex;
    align-items: center;
    justify-items: start;

    &--right {
      justify-content: flex-end;
    }
  }

  &__input-box {
    display: flex;
    align-items: stretch;
    width: 100%;
  }

  &__label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    flex-grow: 2;

    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    outline-color: rgba(0,0,0,.42);
    outline-width: 1px;
    outline-style: solid;

    &:hover:not(&--error) {
      outline-color: rgba(0,0,0,.87);
    }

    &:hover#{&}--focused {
      outline-color: transparent;
    }

    &:has(textarea:focus):not(&--error),
    &:has(input:focus):not(&--error),
    &--focused:not(&--error) {
      border-color: #2b5593;
      outline-color: transparent;
      --input-wrapper-icon-color: #2b5593;
    }

    &--error {
      border-color: #ff5722;
      outline-color: transparent;
      --input-wrapper-icon-color: #ff5722;
    }
  }

  &__label-text {
    position: absolute;
    left: 12px;
    top: 6px;
    padding: 0 3px;
    color: $placeholder-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    transition: all 0.2s;
    background-color: #fff;

    &--error {
      color: #ff5722;
    }

    &--transform {
      transform: translate(-2.5px, -80%) scale(0.75);
    }
  }

  &__input-slot-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $default-icon-color;
    padding-right: .5rem;

    &--error {
      color: #ff5722;
    }
  }

  &__input-slot {
    display: flex;
    align-items: center;
    width: var(--input-slot-width, 100%);

    font-size: 1rem;

    & > input,
    & > textarea,
    & > select {
      border: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      caret-color: #2b5593;

      flex-grow: 2;
    }

    & > select {
      cursor: pointer;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: transparent;
    }
    input::-moz-placeholder,
    textarea::-moz-placeholder {
      color: transparent;
    }
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
      color: transparent;
    }
    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: transparent;
    }

    input:focus::-webkit-input-placeholder,
    textarea:focus::-webkit-input-placeholder {
      color: $placeholder-color;
    }
    input:focus::-moz-placeholder,
    textarea:focus::-moz-placeholder {
      color: $placeholder-color;
    }
    input:focus:-ms-input-placeholder,
    textarea:focus:-ms-input-placeholder {
      color: $placeholder-color;
    }
    input:focus:-moz-placeholder,
    textarea:focus:-moz-placeholder {
      color: $placeholder-color;
    }

    & > input,
    & > select {
      padding: 0 12px;
      height: $input-height;
      width: 100%;
    }

    & > textarea {
      padding: 8px 12px;
      min-height: $input-height;
    }

    & > input::-webkit-calendar-picker-indicator {
      display: none !important;
    }

    &--focused + #{$root}__label-text,
    &:has(input:focus) + #{$root}__label-text,
    &:has(textarea:focus) + #{$root}__label-text,
    &:has(select:focus) + #{$root}__label-text,
    &:has(textarea:not(:placeholder-shown)) + #{$root}__label-text,
    &:has(select:not(:placeholder-shown)) + #{$root}__label-text,
    &:has(input:not(:placeholder-shown)) + #{$root}__label-text {
      transform: translate(-15%, -80%) scale(0.75);
    }

    &--focused + #{$root}__label-text:not(#{$root}__label-text--error),
    &:has(textarea:focus) + #{$root}__label-text:not(#{$root}__label-text--error),
    &:has(input:focus) + #{$root}__label-text:not(#{$root}__label-text--error) {
      color: #2b5593;
    }

    & input:focus + #{$root}__input-slot-right {
      color: #2b5593;
    }

    // if the <input /> is focused and it is deep
    & .input:focus-within + #{$root}__input-slot-right {
      color: #2b5593;
    }
  }

  &__loading-bar-container {
    position: absolute;
    height: 2px;
    bottom: -1px;
    width: 100%;
    overflow-x: hidden;
  }

  &__loading-bar {
    height: 2px;
    background-color: #2b5593;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    animation: load 2s infinite linear;
  }

  &__error-msg,
  &__hints,
  &__info-slot {
    font-size: 12px;
  }

  &__error-msg,
  &__hints {
    display: inline-block;
  }

  &__hints {
    color: #00000099;
  }

  &__error-msg {
    color: #ff5722;
  }
}

@keyframes load {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
